import React from "react";
import Index from "../views/Support/Index";
import Layout from "../components/Layout";

function Support({location}) {
  return (
    <div>
 <Layout location={location}>
        <Index  location={location}/>
      </Layout>
    </div>
  );
}

export default Support;
