import React from "react";
import Header from "./header";
import SystemStatus from "./SystemStatus";
import Speak from "./../../components/Speak";
function Index({location}) {
  return (
    <div>
      <Header />
      <SystemStatus />
      <Speak  location={location}/>
    </div>
  );
}

export default Index;
